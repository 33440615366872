import { Controller } from "@hotwired/stimulus";
import { compact } from 'lodash';

// const currencyFormatter = new Intl.NumberFormat(`en-US`, {
//   currency: `USD`,
//   style: 'currency',
// });

// function couponName(coupon) {
//   const { kind, code } = coupon;
//   return compact([kind, code]).join('-');
// }

// function couponTemplate({ coupon, subtotal }) {
//   if (Object.keys(coupon).length === 0) {
//     return '';
//   }

//   const couponText =
//     coupon.percent_off !== null
//       ? `${couponName(coupon)}: ${currencyFormatter.format(
//           subtotal * (coupon.percent_off / 100.0)
//         )} (${coupon.percent_off}%) off`
//       : `${couponName(coupon)}: ${currencyFormatter.format(
//           coupon.amount_off / 100.0
//         )} off`;

//   return `
//     <div class="order-section-label d-flex align-items-center gap-2 account-text-muted c-text-sm">
//       <i class="icon-reward-tag small c-bg-positive-green icon-only"></i>
//       ${couponText}
//     </div>
//   `;
// }

// function originalPriceTemplate({ discount, subtotal }) {
//   if (discount === 0) {
//     return '';
//   }
//   return `<small><del>${currencyFormatter.format(subtotal)}</del></small>`;
// }

// function totalTemplate({ coupon, discount, subtotal, total }) {
//   return `
//     <div class="d-flex justify-content-between align-items-center gap-2">
//       <span class="monument-regular fw-bold">Total:</span>
//       <span>
//         <span class="monument-regular fw-bold">
//           ${originalPriceTemplate({ discount, subtotal })}
//         </span>
//         <span class="monument-regular fw-bold">
//           ${currencyFormatter.format(total)}
//         </span>
//       </span>
//     </div>
//     ${couponTemplate({ coupon, subtotal })}
//   `;
// }

// export default class extends Controller {
//   static targets = ['quantity', 'sku', 'totalWrapper', 'subquantity', 'size'];
//   static values = {
//     coupon: Object,
//   };

//   connect() {
//     this.render();
//   }

//   recalculate() {
//     this.render();
//   }

//   get selectedSku() {
//     if (!this.hasSkuTarget) {
//       return null;
//     }

//     return this.skuTargets.find((element) => element.checked);
//   }

//   render() {
//     if (this.hasTotalWrapperTarget) {
//       this.totalWrapperTarget.innerHTML = totalTemplate({
//         coupon: this.couponValue,
//         discount: this.discount,
//         subtotal: this.subtotal,
//         total: this.total,
//       });
//     }

//     if (this.hasSizeTarget) {
//       this.sizeTarget.innerHTML = this.selectedSize;
//     }
//   }

//   get selectedSize() {
//     const s = this.selectedSku.dataset.skuSize;
//     return s;
//   }

//   // NOTE: This is setup to work with percent and amount off coupons. It does
//   //       not work with evergreen coupons (100% off) currently.
//   get discount() {
//     if (
//       this.couponValue === null ||
//       Object.keys(this.couponValue).length === 0
//     ) {
//       return 0;
//     }

//     return this.couponValue.percent_off !== null
//       ? this.subtotal * (this.couponValue.percent_off / 100.0)
//       : this.couponValue.amount_off / 100.0;
//   }

//   get subtotal() {
//     if (this.selectedSku && this.hasQuantityTarget) {
//       const skuPrice = Number(this.selectedSku.dataset.skuPrice);
//       const quantity = Number(this.quantityTarget.value);

//       if (!isNaN(skuPrice) && !isNaN(quantity)) {
//         return skuPrice * quantity;
//       }
//     }
//     return 0;
//   }

//   get total() {
//     return this.subtotal - this.discount;
//   }
// }
