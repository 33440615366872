// src/controllers/content_loader_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.load()
    //this.token()
  }

  load() {

    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }

  // https://gomakethings.com/how-to-use-the-fetch-method-to-make-multiple-api-calls-with-vanilla-javascript/
  // https://www.codegrepper.com/code-examples/whatever/fetch

  // // Example POST method implementation:
  // async function postData(url = '', data = {}) {
  //   // Default options are marked with *
  //   const response = await fetch(url, {
  //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //     mode: 'cors', // no-cors, *cors, same-origin
  //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //     //credentials: 'same-origin', // include, *same-origin, omit
  //     headers: {
  //       'Content-Type': 'application/json'
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     redirect: 'follow', // manual, *follow, error
  //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //     body: JSON.stringify(data) // body data type must match "Content-Type" header
  //   });
  //   return response.json(); // parses JSON response into native JavaScript objects
  // }

  // token() {
  //   //const value = this.queryTarget.value
  //   const params = {
  //     username: "a",
  //     password: "b"
  //   }
  //   const url = `https://api.shipohi.com/api/v1/token/`
  //   const settings = {
  //     method: 'POST',
  //     body: JSON.stringify(params),
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   }
  //   fetch(url, settings)
  //     then(function(response) {

  //     }).then(function(data){

  //       return fetch('')
  //     })
  //   })
  // }


  updateSession(sessionData) {
    // add CSRF
    // update cart count
    // update offer banner
  }
}
