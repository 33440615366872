import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    if (this.hasInputTarget) {
      $(this.inputTarget).rangeslider({
        onSlideEnd: (_position, _value) => {
          this.inputTarget.dispatchEvent(
            new Event("change", { bubbles: true })
          );
        },
        polyfill: false,
      });
    }
  }

  decrease(event) {
    event.preventDefault();
    if (this.hasInputTarget) {
      const step = parseFloat(this.inputTarget.step);
      this.inputTarget.value =
        Number(this.inputTarget.value) - (isNaN(step) ? 999 : step);
      this.inputTarget.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }

  disconnect() {
    if (this.hasInputTarget) {
      $(this.inputTarget).rangeslider("destroy");
    }
  }

  increase(event) {
    event.preventDefault();
    if (this.hasInputTarget) {
      const step = parseFloat(this.inputTarget.step);
      this.inputTarget.value =
        Number(this.inputTarget.value) + (isNaN(step) ? 1 : step);
      this.inputTarget.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }
}
